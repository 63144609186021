import { configureStore } from "@reduxjs/toolkit";

import {beadsReducer} from './Post/beads-slice';


export const store = configureStore({
  reducer: {
    beads: beadsReducer,
  },
  devTools: true,
});

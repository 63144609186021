import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { Container, createTheme, MantineProvider } from '@mantine/core';

import { IconStorm } from '@tabler/icons-react';
import { AppShell,  Affix } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import './App.css';

import {TabsB as Tabs } from "../Tabs";
import Test from "../yoopta";
import '@mantine/core/styles.css';
const theme = createTheme({
  /** Put your mantine theme override here */
});




function App() {
  const [opened, { toggle }] = useDisclosure();

  return (
    <MantineProvider theme={theme}>
        <AppShell
      header={{ height: 60 }}
     
    >
       <AppShell.Header>
       
        <Affix position={{ top: 5, left: '45%' }}><IconStorm/> Oko Buri</Affix>
  </AppShell.Header>
    <AppShell.Main>  
    <BrowserRouter>
    <Container fluid>
  
      <Routes>
        <Route path="/" element={<Tabs />} />
        <Route path="/:tabValue" element={<Tabs />} />
        <Route path="/test" element={<Test />} />
      </Routes>

    </Container>
  </BrowserRouter>
  </AppShell.Main>
 



</AppShell>
  </MantineProvider>
  );
}

export default App;

import { Textarea, Button, TagsInput, Fieldset, Group, Select, ComboboxItem} from '@mantine/core';
import { useState } from 'react';

//import { useNavigate, useParams } from "react-router-dom";
//useSelector,
import {  useDispatch, useSelector } from "react-redux";
import {data} from './data'
import {addPost, selectBeads} from './beads-slice';

export function Text() {
  let beads = useSelector(state => selectBeads(state, 'list'));
  beads = beads.map((v:any)=>({label:v?.data?.label, value:v.id}));
    const [value, setValue] = useState('');
    const [valueTag, setValueTag] = useState<string[]>([]);
    const [valueBeads, setValueBeads] = useState<ComboboxItem | null>(beads[0]);
   
    const dispatch =useDispatch();
    return (<> <Fieldset legend="Add Essema">
      <Textarea
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        autosize
        minRows={4}
        label="text"
      />
      <TagsInput
        value={valueTag} onChange={setValueTag}
        label="keywords"
        placeholder='type and press enter'
    />
      <Select label="notebook" data={beads} value={valueBeads?.value} onChange={(_, option)=>setValueBeads(option)} />
      </Fieldset>
      <Group justify="flex-start" mt="md">
      <Button onClick={()=>{
        dispatch(addPost(value, valueTag, valueBeads));
        setValue('');
        setValueTag([])
        }}>save</Button>
      </Group>
      </>
    );
}

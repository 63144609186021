import YooptaEditor, { createYooptaEditor, createYooptaMark, YooptaMarkProps } from '@yoopta/editor';

import Paragraph from '@yoopta/paragraph';
import Blockquote from '@yoopta/blockquote';
import Embed from '@yoopta/embed';
import Image from '@yoopta/image';
import Link from '@yoopta/link';
import Callout from '@yoopta/callout';
import Video from '@yoopta/video';
import File from '@yoopta/file';
import { NumberedList, BulletedList, TodoList } from '@yoopta/lists';
import { Bold, Italic, CodeMark, Underline, Strike, Highlight } from '@yoopta/marks';
import { HeadingOne, HeadingThree, HeadingTwo } from '@yoopta/headings';
import Code from '@yoopta/code';
import ActionMenuList, { DefaultActionMenuRender } from '@yoopta/action-menu-list';
import Toolbar, { DefaultToolbarRender } from '@yoopta/toolbar';
import LinkTool, { DefaultLinkToolRender } from '@yoopta/link-tool';
import s from './withCustomMark.module.scss';


import { uploadToCloudinary } from './cloudinary';
import { useEffect, useMemo, useRef } from 'react';
import { WITH_CUSTOM_MARK_INIT_VALUE } from './initValue';

const plugins = [
  Paragraph,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Blockquote,
  Callout,
  NumberedList,
  BulletedList,
  TodoList,
  Code,
  Link,
  Embed,
  Image.extend({
    options: {
      async onUpload(file: File) {
        const data = await uploadToCloudinary(file, 'image');

        return {
          src: data.secure_url,
          alt: 'cloudinary',
          sizes: {
            width: data.width,
            height: data.height,
          },
        };
      },
    },
    
  }),
  Video.extend({
    options: {
      onUpload: async (file: File) => {
        const data = await uploadToCloudinary(file, 'video');
        return {
          src: data.secure_url,
          alt: 'cloudinary',
          sizes: {
            width: data.width,
            height: data.height,
          },
        };
      },
    },
  }),
  File.extend({
    options: {
      onUpload: async (file: File) => {
        const response = await uploadToCloudinary(file, 'auto');
        return { src: response.url };
      }
    },
  }),
];

const TOOLS = {
  ActionMenu: {
    render: DefaultActionMenuRender,
    tool: ActionMenuList,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
};

type SuperscriptMarkProps = YooptaMarkProps<'superscript', boolean>;

const CUSTOM_SUPERSCRIPT_MARK = createYooptaMark<SuperscriptMarkProps>({
  type: 'superscript',
  render: (props) => {
    return <sup className={s.superscript}>{props.children}</sup>;
  },
  hotkey: 'mod+p',
});

const MARKS = [Bold, Italic, CodeMark, Underline, Strike, Highlight, CUSTOM_SUPERSCRIPT_MARK];

function WithCustomMark() {
  const editor = useMemo(() => createYooptaEditor(), []);
  const selectionRef = useRef(null);

  useEffect(() => {
    function handleChange(value: any) {
      console.log('value', value);
    }
    editor.on('change', handleChange);
    return () => {
      editor.off('change', handleChange);
    };
  }, [editor]);

  return (
    <div
    style={{paddingLeft:"10%"}}
      className="md:py-[100px] md:pl-[200px] md:pr-[80px] px-[20px] pt-[80px] pb-[40px] flex justify-center"
      ref={selectionRef}
    >
      <YooptaEditor
        editor={editor}
        // @ts-ignore
        plugins={plugins}
        tools={TOOLS}
        marks={MARKS}
        style={{width:"100%"}}
        selectionBoxRoot={selectionRef}
        value={WITH_CUSTOM_MARK_INIT_VALUE}
        autoFocus
      />
    </div>
  );
}

export default WithCustomMark;
export let data =[
    {id:'red',
     position: { x: 0, y: 0 }, 
     data: { label: 'number one' }, 
      type: 'bidirectional',
    beads:[
        {id:'red',
        text:'test 1',
        "tags": ["Peace", "Global"],
        },
        {id:'yellow',
        text:'test 2',
        "tags": ["smart", "key"],
        },
        {id:'green',
        text:'test 3',
        "tags": ["JavaScript"],
        },
      
      ]
    }
  ]
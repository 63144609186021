import cx from "clsx";
import {
  Badge,
  Notification,
  Chip,
  ChipGroup,
  Group,
  Paper,
  rem,
  Space,
  Text,
  TextInput,
  Button,
  Fieldset,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { IconGripVertical, IconStorm } from "@tabler/icons-react";
import classes from "./style.module.css";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addList, moveList, orderList, selectBeads } from "./beads-slice";
function Esse({ list }: any) {
  const dispatch = useDispatch();

  const items = list?.map((item: any, index: any) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </div>

          <div>
            <Text>{item.text}</Text>
            <ChipGroup>
              <Group justify="center">
                {item.tags?.map((value: any) => (
                  <Chip size="xs">{value}</Chip>
                ))}
              </Group>
            </ChipGroup>
          </div>
        </div>
      )}
    </Draggable>
  ));

  return <>{items}</>;
}
export function List() {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const beads = useSelector((state) => selectBeads(state, "list"));
  const getList = (state: any, lisId: any) => {
    
    return state[state.findIndex(({ id }: any) => id === lisId)];
  };
  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    console.log(source, destination);
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      dispatch(
        orderList({
          id:source.droppableId,
          list: getList(beads, source.droppableId).beads,
          startIndex: source.index,
          endIndex: destination?.index || 0,
        })
      );
    } else {
      dispatch(
        moveList(
          {sourceList:getList(beads, source.droppableId).beads,
          destinationList: getList(beads, destination.droppableId).beads || [],
          source,
          destination}
        )
      );
    }
  };
  return (
    <>
      <Fieldset>
        <TextInput
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          label="add list"
        />
        <Button
          onClick={() => {
            dispatch(addList(value));
            setValue("");
          }}
        >
          save
        </Button>
      </Fieldset>
      <DragDropContext onDragEnd={onDragEnd}>
        {beads.map(({ id, data: { label }, beads }: any) => {
          return (
            <Droppable droppableId={id} direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Notification icon={<IconStorm />} color={"grey"}>
                    {label}
                  </Notification>

                  <Paper shadow="xs" p="xl">
                    <Esse list={beads} />
                  </Paper>
                  <Space h="lg" />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
    </>
  );
}
//({ destination, source }) => dispatch(orderList({list, startIndex: source.index, endIndex: destination?.index || 0 }))
//https://codesandbox.io/p/sandbox/ql08j35j3q?file=%2Findex.js%3A24%2C1-36%2C3

import React, { useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  Edge,
  MarkerType,
  updateEdge,
  EdgeTypes,
  Position,
  ConnectionMode,
} from 'reactflow';
//import CustomEdge from './CustomEdge';
import BiDirectionalEdge from './BiDE';
import FloatingEdge from './SimpleFloatingEdge';
import BiDirectionalNode from './BiDN';
import 'reactflow/dist/style.css';
 import './edge.css';
import { useDispatch, useSelector } from 'react-redux';
import { orderFlow, selectBeads } from './beads-slice';

import { useDebouncedEffect } from "./deb";

const initialEdges = [{ id: 'e1-2', source: '1', target: '2',  },
{
    id: 'edge-bi-1',
    source: 'bi-1',
    target: 'bi-2',
  
  },
  {
    id: 'edge-bi-2',
    source: 'bi-2',
    target: 'bi-1',
   
  },
];
//type: 'step',
//const fitViewOptions = { padding: 4 };
const edgeTypes = {
    bidirectional: BiDirectionalEdge,
    floating:FloatingEdge
  };
  
  const nodeTypes = {
    bidirectional: BiDirectionalNode,
  };

const isValidConnection = (connection:Connection) => {
   
    return true;
};
export default  () => {
  const beads = useSelector((state) => selectBeads(state, "list"));
    const edgeUpdateSuccessful = useRef(true);
    const [nodes, setNodes, onNodesChange] = useNodesState(beads);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = useCallback((params: Edge | Connection) => setEdges((els) => {
      //console.log(params, els);
      const connected = els.filter(({source})=>source !== params.source);
      //console.log(connected, els);
    
      return addEdge(params, connected);}), []);
  
    const onEdgeUpdateStart = useCallback(() => {
     // console.log('onEdgeUpdateStart')
      edgeUpdateSuccessful.current = false;
    }, []);
  
    const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
     // console.log('onEdgeUpdate')
      setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);
  
    const onEdgeUpdateEnd = useCallback((_: any, edge: { id: string; }) => {
     // console.log('onEdgeUpdateEnd')
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }
  
      edgeUpdateSuccessful.current = true;
    }, []);
    const dispatch = useDispatch();
    useEffect(() => {
      setNodes(beads)
    }, [beads]);
    
      useDebouncedEffect(() => dispatch(orderFlow(nodes)), [nodes], 1000);
      
   
  
    return (
        <div className='simple-floatingedges' >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        snapToGrid
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        isValidConnection={isValidConnection}
        fitView
        attributionPosition="top-right"
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
      
        connectionMode={ConnectionMode.Loose}

        defaultEdgeOptions={{
            type: 'floating',
           
            markerEnd: {
            type: MarkerType.Arrow,
            
          },
          style: {
            strokeWidth: 1,
            
          },
        }}
      >
        <Controls />
      </ReactFlow>
      </div>
    );
  }
  // addEdge({ ...params, type: 'floating', markerEnd: { type: MarkerType.Arrow } }, eds)

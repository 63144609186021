import { useNavigate, useParams } from 'react-router-dom';
import { rem, Tabs } from '@mantine/core';
import { IconPlus, IconSitemap, IconArrowsSort } from '@tabler/icons-react';
import { Text, List, Beads} from '../Post';

export function TabsB() {
  const navigate = useNavigate();
  const { tabValue } = useParams();
  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <Tabs
      value={tabValue || 'create'}
      onChange={(value) => navigate(`/${value}`)}
    >
      <Tabs.List>
        <Tabs.Tab value="create" 
            leftSection={<IconPlus style={iconStyle} />}>
           create</Tabs.Tab>
        <Tabs.Tab value="sort"
          leftSection={<IconArrowsSort style={iconStyle} />}>
          sort </Tabs.Tab>
        <Tabs.Tab value="edit"
        leftSection={<IconSitemap style={iconStyle} />}>
        beads</Tabs.Tab>
        
        
      </Tabs.List> 
      <Tabs.Panel value="create" pt="xs">
      <Text/>
      </Tabs.Panel>

      <Tabs.Panel value="edit" pt="xs">
        <Beads/>
      </Tabs.Panel>
      <Tabs.Panel value="sort" pt="xs">
      <List/>
      </Tabs.Panel>
      
    </Tabs>
  );
}
import {createSlice,createAsyncThunk, nanoid} from '@reduxjs/toolkit';

import { data } from './data';
const fetchUserById = createAsyncThunk(
  'users/fetchByIdStatus',
  async (userId, thunkAPI) => {
    const response = await thunkAPI.fetchById(userId)
    return response.data
  },
)
const beadsSlice = createSlice({
  name: '@@beads',
  initialState: data,
  reducers: {
    addPost: {
      reducer: (state, action) => {
      const list = state.find(({id})=>id == action.payload.list.value)
        list.beads.push(action.payload);
      },
      prepare: (text, tags, list) => ({
        payload: {
          text,
          id: nanoid(),
          tags,
          list
        }
      })
    },
   
    addList: {
      reducer: (state, action) => {
  
        state.push(action.payload)

        
      },
      prepare: (label) => ({
        payload: {
          label,
          id: nanoid(),
          position: { x: 0, y: 0 }, data: { label },  type: 'bidirectional'
        }
      })
    },
    orderList: (state, action)=>{
      const {id, list, startIndex, endIndex} =action.payload;
     const bead = reorder(list, startIndex, endIndex);
     
     const sr = state.find((v)=>v.id === id);
     sr.beads = bead;
    
    },
    moveList: (state, action)=>{
      const {sourceList, destinationList, source, destination} =action.payload;
      console.log( action)
     const bead = move(sourceList, destinationList, source, destination);
     const sr = state.find(({id})=>id === source.droppableId);
     sr.beads = bead[source.droppableId];
     const ds = state.find(({id})=>id === destination.droppableId);
     ds.beads = bead[destination.droppableId];
   
    },
    orderFlow: (state, action)=>{
     return action.payload;
    
    },
    sortBead: (state) => {
      state.sort((a,b)=>a.order > b.order ? 1 : -1)
    },
    bindBead: (state, action,) => {
      const id = action.payload;
      const bead = state.find((bead) => bead.id === id); 
      const pointedBead = state.find((bead) => bead.targetId === bead.id);
     
      if( pointedBead)  {
        //set bead connection with child
          pointedBead.targetId = bead.id;
          bead.order = pointedBead.order;
            }
        else{
        //set pointer to parent
          bead.targetId = id;
        
        }
        
    },
    unbindBead: (state, action) => {
  
     
      const id = action.payload;
      const pointedBead = state.find((bead) => bead.targetId === bead.id);
      if(pointedBead)pointedBead.targetId =''; //reset pointer from prev bead 
      const bead = state.find((bead) => bead.id === id);
  
      bead.targetId = id; //set pointer to selected bead
    },
   
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      // Add user to the state array
      state.entities.push(action.payload)
    })
  },
 
});
export const {bindBead, unbindBead, sortBead, addList, addPost, orderList, moveList, orderFlow} = beadsSlice.actions;

export const beadsReducer = beadsSlice.reducer;

export const selectBeads = (state, filter) => {
  switch (filter) {
    case 'sort': {
      
      return state.beads;
    }
    
    default: {
      return state.beads;
    }
  }
}
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

